.further-links {
    display: flex;
    width: 100%;
    justify-content: center;
    max-width: 500px;
}

.further-links a {
    font-family: "Circular";
    font-weight: 700;
    font-size: 2rem;
    margin: 0 0 1rem 0;
    letter-spacing: -0.1rem;
    text-align: left;
    text-decoration: none;
    color: var(--color-secondary-lighter);
    display: flex;
    transition: all 0.1s ease-in-out;
}

.further-links a p {
    border-bottom: 0.2rem solid var(--color-secondary-lighter);
    margin: 0;
    padding-bottom: 0.3rem;
    transition: all 0.1s ease-in-out;
}

.further-links a .forward-icon {
    width: 3rem;
    height: 3rem;
    padding-bottom: 0.3rem;
    margin-left: 0.5rem;
    transition: all 0.1s ease-in-out;
}

.further-links a .launch-icon {
    width: 3rem;
    height: 3rem;
    padding-bottom: 0.3rem;
    margin-left: 0.5rem;
    transform: rotate(-45deg);
    transition: all 0.1s ease-in-out;
}

.further-links a:hover {
    color: white;
}

.further-links a:hover p {
    border-bottom: 0.2rem solid transparent;
}

.further-links a:hover .forward-icon {
    transform: translateX(0.5rem);
}

.further-links a:hover .launch-icon {
    transform: translate(0.25rem,-0.25rem) rotate(-45deg);
}

.footer-text {
    font-family: "Inter";
    font-size: 0.8rem;
    margin: 0;
    line-height: 1.4em;
    text-align: center;
}

.footer-text .boring-link {
    color: var(--color-secondary-lighter);
}

.further-links + .fancy-link {
    margin-top: 3rem;
}

.fancy-link + .footer-text {
    margin-top: 4rem;
}

@media only screen and (max-width: 500px) {
    .further-links {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        max-width: 500px;
    }
}