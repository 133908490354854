.horizontal-textbox {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.textbox-container + .textbox-container {
    margin-left: 1rem;
}

.horizontal-textbox + .textbox-container {
    margin-top: 1rem;
}

.textbox-container + .button {
    margin-top: 1rem;
}

.connect h3 + .icon-link {
    margin-top: 1rem;
}

.contact .button {
    width: fit-content;
    align-self: flex-end;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 833px) {
    .horizontal-textbox {
        display: flex;
        flex-direction: column;
    }

    .textbox-container + .textbox-container {
        margin-left: 0rem;
        margin-top: 1rem;
    }

    .contact .button {
        width: 100%;
        align-self: center;
    }
}