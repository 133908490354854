.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

:root {
    --color-primary: #4E896B;
    --color-primary-lighter: #6EA379;
    --color-primary-lightest: #A4C09A;
    --color-primary-darker: #286053;
    --color-primary-darkest: #144840;
    --color-secondary: #FFF700;
    --color-secondary-lighter: #FFFCBD;
    --color-accent: #6BCCDD;
    --color-accent-lighter: #6FD4E6;
    --color-accent-darker: #4688EA;
    --color-white: #FFFFFF;
    --color-black: #000000;
}

h1 {
    font-family: "Circular";
    font-weight: 700;
    font-size: 2.5rem;
    margin: 0 0 1rem 0;
    letter-spacing: -0.1rem;
    text-align: left;
}

h2 {
    font-family: "Circular";
    font-weight: 700;
    font-size: 2rem;
    margin: 0 0 1rem 0;
    letter-spacing: -0.1rem;
    text-align: left;
}

h3 {
    font-family: "Inter";
    font-weight: 600;
    margin: 0;
    text-align: left;
    font-size: 1.2rem;
}

h4 {
    font-family: "Inter";
    font-weight: 700;
    margin: 0;
    text-align: left;
    font-size: 1rem;
}

p.body-text {
    font-family: "Inter";
    font-size: 1.2rem;
    margin: 0;
    line-height: 1.4em;
    text-align: left;
}

a.boring-link {
    font-family: "Inter";
    text-decoration: none;
    transition: all 0.1s ease-in-out;
    color: var(--color-secondary-lighter);
}

a.boring-link:hover {
    color: white;
    background-color: rgba(0,0,0,0.3);
}

.page-enter {
    opacity: 0;
    transform: translateY(-1rem);
}

.page-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}

.page-exit {
    opacity: 1;
}

.page-exit-active {
    opacity: 0;
    transform: translateY(1rem);
    transition: opacity 300ms, transform 300ms;
}

strong {
    color: var(--color-secondary);
}