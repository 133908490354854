.fancy-link {
    text-decoration: none;
    font-family: "Inter";
    font-weight: 600;
    font-size: 1.05rem;
    color: var(--color-secondary-lighter);
    border: 0.2rem solid var(--color-secondary-lighter);
    border-radius: 0.8rem;
    transition: all 0.1s ease-in-out;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
    cursor: pointer;
}

.fancy-link:hover {
    border: 0.2rem dotted transparent;
    /* padding: 0.45rem 0.9rem; */
    background-color: rgba(256,256,256,0.1);
    color: var(--color-secondary);
}

.fancy-link:active {
    transform: scale(0.9);
}