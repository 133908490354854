@font-face {
    font-family: "Circular";
    src: url("./assets/fonts/Circular-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Circular";
    src: url("./assets//fonts/Circular-Bold.ttf") format("truetype");
    font-weight: 700;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');