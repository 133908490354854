.child-container {
    padding: 3rem 2rem;
    display: flex;
    justify-content: center;
}

.child-container .section {
    display: flex;
    width: 100%;
    max-width: 960px;
}

.section-fragment {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.section-fragment-left {
    justify-content: space-between;
}

@media only screen and (max-width: 833px) {
    .child-container .section {
        flex-direction: column !important;
    }

    .section-fragment + .section-fragment {
        margin-top: 3rem;
    }
}