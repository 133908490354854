.hero {
    height: 30rem;
    display: flex;
    padding: 5rem 2rem 0 2rem;
    /* max-width: 70rem; */
    justify-content: center;
    margin: auto;
    background: url("../assets/images/phillmont-hero.png"), linear-gradient(to right, #e9e9e9 0%, #e9e9e9 50%, white 50%, white 100%);
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    background-color: #e9e9e9 black;
}

.hero-fragment {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 10rem;
    margin-top: -5rem;
    max-width: 800px;
}

.hero h1 {
    margin: 0;
}

.hero mark {
    line-height: 0em;
    background-color: var(--color-secondary);
    padding: 0 0.5rem 1.5rem 0.5rem;
    margin: 1.6rem -0.5rem 0 -0.5rem;
    display: flex;
    justify-content: flex-end;
}

h1 + .button {
    margin-top: 2rem;
}

.link-all-projects {
    font-family: "Circular";
    font-weight: 700;
    font-size: 2rem;
    margin: 3rem 0 1rem 0;
    letter-spacing: -0.1rem;
    text-align: left;
    text-decoration: none;
    color: var(--color-primary-darkest);
    display: flex;
}

.link-all-projects p {
    border-bottom: 0.2rem solid var(--color-primary-darkest);
    margin: 0;
    padding-bottom: 0.3rem;
    transition: all 0.1s ease-in-out;
}

.link-all-projects .forward-icon {
    width: 3rem;
    height: 3rem;
    padding-bottom: 0.3rem;
    margin-left: 0.5rem;
    transition: all 0.1s ease-in-out;
}

.link-all-projects:hover {
    color: var(--color-accent);
}

.link-all-projects:hover p {
    border-bottom: 0.2rem solid transparent;
}

.link-all-projects:hover .forward-icon {
    transform: translateX(0.5rem);
}

.showcase {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
}

.showcase h2 {
    text-align: left;
    color: var(--color-primary-darkest);
    width: 100%;
    max-width: 960px;
    margin: 0 0 3rem 0;
}

@media only screen and (max-width: 1446px) {
    .hero {
        background-position: right;
        background-size: cover;
    }
}

@media only screen and (max-width: 1130px) {
    .hero {
        background: url("../assets/images/phillmont-hero-2.png"), linear-gradient(to right, #e9e9e9 0%, #e9e9e9 50%, white 50%, white 100%);
        background-position: right;
        background-size: cover;
    }
}

@media only screen and (max-width: 726px) {
    .hero {
        background: url("../assets/images/phillmont-hero-3.png"), linear-gradient(to right, #e9e9e9 0%, #e9e9e9 50%, white 50%, white 100%);
        background-position: center;
        background-size: cover;
        height: 60rem;
    }

    .hero-fragment {
        justify-content: flex-start;
        align-items: center;
        margin-top: 3rem;
        margin-right: 0rem;
    }
}

@media only screen and (max-width: 634px) {
    .hero {
        background: url("../assets/images/phillmont-hero-4.png"), linear-gradient(to right, #e9e9e9 0%, #e9e9e9 50%, white 50%, white 100%);
        background-position: center;
        background-size: cover;
        height: 45rem;
    }
}