.showcase-item-container {
    width: 100%;
    border-radius: 1.5rem;
}

.showcase-item {
    max-width: 960px;
    height: 100%;
    margin: auto;
    display: flex;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
}

.showcase-item-logo {
    height: 5rem;
}

.showcase-item-fragment {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.showcase-item-fragment-left {
    align-items: flex-start;
    justify-content: space-between;
    padding: 3rem 0;
}

.showcase-item-fragment-right {
    align-items: flex-end;
    visibility: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
}

.showcase-item-logo + h2 {
    margin-top: 1rem;
}

.showcase-item-fragment p.body-text {
    font-size: 1.1rem;
}

.showcase-item h2 {
    padding: 0;
    margin-bottom: 0;
}

.showcase-item-fragment h2 + h3 {
    margin-top: 0.3rem;
}

.showcase-item-fragment h3 + h4 {
    margin-top: 2rem;
}

.showcase-item-fragment h4 + h3 {
    margin-top: 0.2rem;
}

.showcase-item-fragment h3 + p.body-text {
    margin-top: 2rem;
}

.showcase-item-fragment p.body-text + .button {
    margin-top: 2rem;
}

.showcase-item-fragment p.body-text + .anchor-button-container {
    margin-top: 2rem;
}

.showcase-item-container + .showcase-item-container {
    margin-top: 2rem;
}

@media only screen and (max-width: 1130px) {
    .showcase-item-container {
        border-radius: 0;
        margin: 0;
        padding: 0rem 2rem;
    }
}

@media only screen and (max-width: 936px) {
    .showcase-item-fragment-left {
        background-color: rgba(256,256,256,0.8);
        margin: 0rem -2rem;
        padding: 2rem 2rem;
    }
}

@media only screen and (max-width: 833px) {
    .showcase-item {
        flex-direction: column;
        background: none !important;
    }

    .showcase-item-fragment-left {
        background-color: rgba(256,256,256,0.3);
        margin: 0rem -2rem;
        padding: 2rem 2rem;
    }

    .showcase-item-fragment-right {
        visibility: visible;
        height: 45rem;
    }

    .showcase-item-fragment .button {
        width: 100%;
    }

    .showcase-item-fragment .anchor-button-container {
        width: 100%;
    }
}

@media only screen and (max-width: 792px) {
    .showcase-item-fragment-right {
        visibility: visible;
        margin: 0 -2rem;
        padding: 0 2rem;
    }
}

@media only screen and (max-width: 726px) {
    .showcase-item-fragment-right {
        height: 100vw;
    }
}