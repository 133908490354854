.icon-link {
    display: flex;
    align-items: center;
    width: fit-content;
}

.icon-link img {
    height: 2.5rem;
    transition: all 0.1s ease-in-out;
}

.icon-link a {
    text-decoration: none;
    font-family: "Inter";
    font-weight: 600;
    font-size: 1.05rem;
    color: var(--color-primary-darker);
    border: 0.2rem solid var(--color-primary-darker);
    border-radius: 0.8rem;
    padding: 0.25rem 0.7rem;
    transition: all 0.1s ease-in-out;
    box-sizing: border-box;
}

.icon-link img + a {
    margin-left: 1rem;
}

.icon-link + .icon-link {
    margin-top: 0.5rem;
}

.icon-link:hover img {
    opacity: 0.8;
}

.icon-link a:hover {
    /* opacity: 0.8; */
    border: 0.2rem solid transparent;
    /* padding: 0.45rem 0.9rem; */
    background-color: rgba(0,0,0,0.07);
    color: var(--color-primary);
}