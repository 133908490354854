.header-container {
    position: fixed;
    width: 100vw;
    display: flex;
    justify-content: center;
    z-index: 99;
}

.header {
    width: calc(100vw - 4rem);
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    margin: 0 2rem;
    max-width: 1100px;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    transition-duration: 0.1s;
    transition-timing-function: ease-in-out;
    transition-property: background-color;
    /* margin: auto; */
}

.header-opaque {
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(250,250,250,0.8);
}

.logo {
    height: 3rem;
    transition: all 0.3s ease-in-out;
}

.logo:hover {
    transform: rotate(360deg);
}

.logo-container {
    height: 100%;
    display: flex;
    align-items: center;
}

.fragment {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.fragment-left {
    justify-content: flex-start;
}

.fragment-right {
    justify-content: flex-end;
}

.brand {
    font-family: "Circular";
    font-size: 1.6rem;
    font-weight: 700;
    text-decoration: none;
    color: black;
    letter-spacing: -0.07rem;
}

.colored-brand {
    color: var(--color-primary-darkest);
}

.link {
    font-family: "Circular";
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--color-primary-darker);
    text-decoration: none;
    transition: ease-in 0.1s;
    letter-spacing: -0.05rem;
    background-color: rgba(0,0,0,0);
    padding: 0.7rem 1.5rem;
    border-radius: 0.7rem;
    display: flex;
    justify-content: center;
}

.link svg {
    margin-left: 0.5rem;
}

.link.focused {
    background-color: rgba(0,0,0,0.07);
}

/* .link + .link {
    margin-left: 2rem;
} */

.link:hover {
    background-color: rgba(0,0,0,0.06);
    opacity: 0.8;
}

.hamburger {
    visibility: hidden;
    width: 0;
    transition: all 0.1s ease-in-out;
    border: none;
    outline: none;
    background-color: rgba(0,0,0,0);
}

.hamburger:active, .close:active {
    transform: scale(0.8);
}

@media only screen and (max-width: 768px) {
    .header {
        width: 100vw;
        height: 5rem;
        padding: 0 2rem;
        margin: 0;
        border-bottom-left-radius: 0rem;
        border-bottom-right-radius: 0rem;
    }

    .fragment-right .link {
        visibility: hidden;
        width: 0;
        padding: 0;
    }

    .hamburger {
        visibility: visible;
        width: fit-content;
    }
}

.menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(250,250,250,0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-property: opacity;
}

.menu.visible {
    visibility: visible;
    opacity: 1;
}

.menu .close-button {
    background-color: rgba(0,0,0,0);
    outline: none;
    border: none;
}

.menu .close {
    width: 3rem;
    height: 3rem;
    color: var(--color-primary-darker);
    background-color: rgba(0,0,0,0.05);
    border-radius: 100rem;
    padding: 0.5rem;
    margin-top: 5rem;
    transition: all 0.1s ease-in-out;
}

.menu-contact-icons {
    display: flex;
    flex-direction: row;
    width: 60vw;
    max-width: 20rem;
    justify-content: space-between;
}

.menu-contact-icons a img {
    height: 3rem;
}

.menu-contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menu-contents a + .menu-contact-icons {
    margin-top: 2rem;
}

.menu-contents .link {
    font-size: 1.5rem;
}