.blob-item-container {
    width: 100%;
    border-radius: 1.5rem;
}

.blob-item {
    max-width: 960px;
    height: 100%;
    margin: auto;
    display: flex;
}

.blob-item-logo {
    height: 5rem;
}

.blob-item-fragment {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.blob-item-fragment-left {
    align-items: flex-start;
    justify-content: space-between;
    padding: 3rem 0;
}

.blob-item-fragment-right {
    justify-content: center;
    align-items: flex-end;
    /* background-size: contain;
    background-repeat: no-repeat;
    background-position: center; */
    margin-left: 2rem;
}

.blob-item-logo + h2 {
    margin-top: 1rem;
}

.blob-item-fragment p.body-text {
    font-size: 1.1rem;
}

.blob-item h2 {
    padding: 0;
    margin-bottom: 0;
}

.blob-item-fragment h2 + h3 {
    margin-top: 0.3rem;
}

.blob-item-fragment h3 + h4 {
    margin-top: 2rem;
}

.blob-item-fragment h4 + h3 {
    margin-top: 0.2rem;
}

.blob-item-fragment h3 + p.body-text {
    margin-top: 2rem;
}

.blob-item-fragment p.body-text + .button {
    margin-top: 2rem;
}

.blob-item-fragment p.body-text + .anchor-button-container {
    margin-top: 2rem;
}

.blob-item-container + .blob-item-container {
    margin-top: 2rem;
}

.showcase-item-container + .blob-item-container {
    margin-top: 2rem;
}

.body-text a {
    font-family: "Inter";
    text-underline-offset: 0.3rem;
    transition: all 0.1s ease-in-out;
    color: inherit;
    font-weight: 500;
}

.body-text a:hover {
    background-color: rgba(0,0,0,0.2);
}

@media only screen and (max-width: 1130px) {
    .blob-item-container {
        border-radius: 0;
        margin: 0;
        padding: 0rem 2rem;
    }
}

@media only screen and (max-width: 936px) {
    .blob-item-fragment-left {
        margin: 0rem -2rem;
        padding: 2rem 2rem;
    }
}

@media only screen and (max-width: 833px) {
    .blob-item {
        flex-direction: column;
        background: none !important;
    }

    .blob-item-fragment-left {
        margin: 0rem -2rem;
        padding: 2rem 2rem;
    }

    .blob-item-fragment-right {
        margin-left: -2rem;
        width: 100vw;
    }

    .blob-item-fragment .button {
        width: 100%;
    }

    .blob-item-fragment .anchor-button-container {
        width: 100%;
    }
}

@media only screen and (max-width: 792px) {
    /* .blob-item-fragment-right {
        visibility: visible;
        margin: 0 -2rem;
        padding: 0 2rem;
    } */
}

@media only screen and (max-width: 726px) {
    /* .blob-item-fragment-right {
        height: 100vw;
    } */
}